<template>
  <v-app>
    <customize-header homeRouter="/bigDataAnalysis/home"
                      homeTitle="大数据分析"
                      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
  </v-app>
</template>

<script>

export default {
  name: 'bigDataAnalysisIndex',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/bigDataAnalysis/home",
        children: []
      },
      {
        title: "产品服务",
        router: "/bigDataAnalysis/productService",
        children: []
      },
      {
        title: "解决方案",
        router: "/bigDataAnalysis/industrySolution",
        children: []
        // renderList: true, 
        // children: [
        //   {
        //     title: "行业解决方案",
        //     router: "/bigDataAnalysis/Solutions/industrySolution"
        //   },
        //   {
        //     title: "角色解决方案",
        //     router: "/bigDataAnalysis/Solutions/roleSolution"
        //   },
        // ]
      }
    ],
  }),
  mounted() {

  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
</style>